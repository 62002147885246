import FlamesLogo from "../images/logos/flames-logo.png";
import JetsLogo from "../images/logos/jets-logo.png";
import OilersLogo from "../images/logos/oilers-logo.png";
import CanucksLogo from "../images/logos/canucks-logo.png";
import MapleLeafsLogo from '../images/logos/maple-leafs-logo.png';
import RaptorsLogo from '../images/logos/raptors-logo.png';
import BlueJaysLogo from '../images/logos/blue-jays-logo.png';
import SenatorsLogo from '../images/logos/senators-logo.png';
import ArgonautsLogo from '../images/logos/argonauts-logo.png';
import BlueBombersLogo from '../images/logos/blue-bombers-logo.png';
import ElksLogo from '../images/logos/elks-logo.png';
import LionsLogo from '../images/logos/lions-logo.png';
import RedblacksLogo from '../images/logos/redblacks-logo.png';
import StampedersLogo from '../images/logos/stampeders-logo.png';
import RoughridersLogo from '../images/logos/roughriders-logo.png';
import TigercatsLogo from '../images/logos/tigercats-logo.png';
import GreyCupLogo from '../images/logos/greycup-logo.png';
import WranglersLogo from '../images/logos/wranglers-logo.png';
import MooseLogo from '../images/logos/moose-logo.png';
import WhitecapsLogo from '../images/logos/whitecaps-logo.png';
import TorontofcLogo from '../images/logos/torontofc-logo.png';
import TaylorSwiftLogo from '../images/logos/taylor-swift-logo.png';
import IIHFLogo from '../images/logos/iihf-logo.png';
import AbbotsfordCanucksLogo from '../images/logos/abbotsford-canucks-logo.png';
import UfcFightNightLogo from '../images/logos/ufc-fight-night-logo.png';

const TEAMS = [
  {
    name: "Flames",
    fullName: "Calgary Flames",
    homeTeamSlug: "calgary-flames",
    type: "NHL",
    zones: ["Press Level", "Second Level", "Lower Bowl"],
    abbreviation: "CGY",
    logo: FlamesLogo
  },
  {
    name: "Jets",
    fullName: "Winnipeg Jets",
    homeTeamSlug: "winnipeg-jets",
    type: "NHL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    abbreviation: "WPG",
    logo: JetsLogo
  },
  {
    name: "Oilers",
    fullName: "Edmonton Oilers",
    homeTeamSlug: "edmonton-oilers",
    type: "NHL",
    zones: ["Upper Bowl", "Lower Bowl", "Premium Zone"],
    abbreviation: "EDM",
    logo: OilersLogo
  },
  {
    name: "Canucks",
    fullName: "Vancouver Canucks",
    homeTeamSlug: "vancouver-canucks",
    type: "NHL",
    zones: ["Upper Bowl", "Lower Bowl", "Club Lower"],
    abbreviation: "VAN",
    logo: CanucksLogo
  },
  {
    name: "Maple Leafs",
    fullName: "Toronto Maple Leafs",
    homeTeamSlug: "toronto-maple-leafs",
    type: "NHL",
    zones: ["Upper Zone", "Lower Zone", "Suites"],
    abbreviation: "TOR",
    logo: MapleLeafsLogo
  },
  {
    name: "Senators",
    fullName: "Ottawa Senators",
    homeTeamSlug: "ottawa-senators",
    type: "NHL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    abbreviation: "OTT",
    logo: SenatorsLogo
  },
  {
    name: "Raptors",
    fullName: "Toronto Raptors",
    homeTeamSlug: "toronto-raptors",
    type: "NBA",
    zones: ["Upper Zone", "Lower Zone", "Premium Zone"],
    abbreviation: "RAPS",
    logo: RaptorsLogo
  },
  {
    name: "Blue Jays",
    fullName: "Toronto Blue Jays",
    homeTeamSlug: "toronto-blue-jays",
    type: "MLB",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    abbreviation: "TBJ",
    logo: BlueJaysLogo
  },
  {
    name: "Stampeders",
    fullName: "Calgary Stampeders",
    homeTeamSlug: "calgary-stampeders",
    type: "CFL",
    zones: ["Lower Zone", "Other", "-"],
    abbreviation: "STA",
    logo: StampedersLogo
  },
  {
    name: "Elks",
    fullName: "Edmonton Elks",
    homeTeamSlug: "edmonton-elks",
    type: "CFL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    abbreviation: "ELK",
    logo: ElksLogo
  },
  {
    name: "Blue Bombers",
    fullName: "Winnipeg Blue Bombers",
    homeTeamSlug: "winnipeg-blue-bombers",
    type: "CFL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    abbreviation: "WBB",
    logo: BlueBombersLogo
  },
  {
    name: "Lions",
    fullName: "BC Lions",
    homeTeamSlug: "bc-lions",
    type: "CFL",
    zones: ["Upper Zone", "Club Seats", "Lower Zone"],
    abbreviation: "BCL",
    logo: LionsLogo
  },
  {
    name: "Argonauts",
    fullName: "Toronto Argonauts",
    homeTeamSlug: "toronto-argonauts",
    type: "CFL",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    abbreviation: "ARG",
    logo: ArgonautsLogo
  },
  {
    name: "Redblacks",
    fullName: "Ottawa Redblacks",
    homeTeamSlug: "ottawa-redblacks",
    type: "CFL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    abbreviation: "ORB",
    logo: RedblacksLogo
  },
  {
    name: "Roughriders",
    fullName: "Saskatchewan Roughriders",
    homeTeamSlug: "saskatchewan-roughriders",
    type: "CFL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    abbreviation: "SSK",
    logo: RoughridersLogo
  },
  {
    name: "Tiger-Cats",
    fullName: "Hamilton Tigercats",
    homeTeamSlug: "hamilton-tigercats",
    type: "CFL",
    zones: ["Upper Zone", "Premium Zone", "Lower Zone"],
    abbreviation: "HAM",
    logo: TigercatsLogo
  },
  {
    name: "Grey Cup",
    fullName: "Grey Cup",
    homeTeamSlug: "grey-cup",
    type: "CFL",
    zones: ["Upper Zone", "Club Seats", "Lower Zone"],
    abbreviation: "GCUP",
    logo: GreyCupLogo
  },
  {
    name: "Wranglers",
    fullName: "Calgary Wranglers",
    homeTeamSlug: "calgary-wranglers",
    type: "AHL",
    zones: ["Press Level", "Second Level", "Lower Bowl"],
    abbreviation: "WRA",
    logo: WranglersLogo
  },
  {
    name: "Moose",
    fullName: "Manitoba Moose",
    homeTeamSlug: "manitoba-moose",
    type: "AHL",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    abbreviation: "MSE",
    logo: MooseLogo
  },
  {
    name: "Abbotsford Canucks",
    fullName: "Abbotsford Canucks",
    homeTeamSlug: "abbotsford-canucks",
    type: "AHL",
    zones: ["-", "-", "Lower Zone"],
    abbreviation: "AC",
    logo: AbbotsfordCanucksLogo
  },
  {
    name: "Whitecaps",
    fullName: "Vancouver Whitecaps FC",
    homeTeamSlug: "vancouver-whitecaps-fc",
    type: "MLS",
    zones: ["General Admission", "Middle Zone", "Lower Zone"],
    abbreviation: "VWFC",
    logo: WhitecapsLogo
  },
  {
    name: "Toronto FC",
    fullName: "Toronto FC",
    homeTeamSlug: "toronto-fc",
    type: "MLS",
    zones: ["Upper Zone", "Lower Zone", "Other"],
    abbreviation: "TFC",
    logo: TorontofcLogo
  },
  {
    name: "Taylor Swift",
    fullName: "Taylor Swift",
    homeTeamSlug: "taylor-swift",
    type: "CONCERT",
    zones: ["Upper Zone", "Middle Zone", "Lower Zone"],
    abbreviation: "TAYLOR",
    logo: TaylorSwiftLogo
  },
  {
    name: "World Junior",
    fullName: "World Junior",
    homeTeamSlug: "world-junior",
    type: "IIHF",
    zones: ["-", "-", "Lower Zone"],
    abbreviation: "WJR",
    logo: IIHFLogo
  },
  {
    name: "UFC Fight Night",
    fullName: "UFC Fight Night",
    homeTeamSlug: "ufc-fight-night",
    type: "UFC",
    zones: ["Upper Bowl", "Lower Bowl", "Premium Zone"],
    abbreviation: "UFC",
    logo: UfcFightNightLogo
  }
];

export default TEAMS;
